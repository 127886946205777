import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import AppGuard from "../../../components/AppGuard";
import App from "./components/App";

export default function Root(props) {
  const { auth0, userState } = props;

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/funding-link-tool"
          render={() => (
            <AppGuard
              app={<App userState={userState} />}
              auth0={auth0}
              freeEntry={false}
              userState={userState}
            />
          )}
          auth0={auth0}
          freeEntry={false}
          userState={userState}
        />
      </Switch>
    </BrowserRouter>
  );
}
