import clsx from "clsx";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useReducer, useState } from "react";

import config from "../../../../config";

import "../scss/App.scoped.scss";

const initialState = {
  name: "",
  organizationId: "",
  website: "",
  linkedin: "",
  yourName: "",
  yourEmail: "",
  yourLinkedin: "",
  introducedBy: "",
  direct: true,
};

const reducer = (state, action) => {
  let newState = Object.assign({}, state);
  newState[action.key] = action.value;
  return newState;
};

const autoSuggest = async (name, token, client, setter) => {
  const urlParams = new URLSearchParams({
    limit: 5,
    access_token: token,
    client,
    order_by: "score",
    order: "desc",
    name,
  });

  const response = await fetch(
    `${config.api.host}/organizations?${urlParams.toString()}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();

  setter(json?.organizations || []);
};

const autoSuggestDebounced = debounce(autoSuggest, 400);

function App(props) {
  const { userState } = props;

  const [copied, setCopied] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const existingOrganizations =
      organizations?.map((organization) => organization.name) || [];

    if (state.name && !existingOrganizations.includes(state.name)) {
      setTimeout(() => setShowSuggestions(true), 1200);
      autoSuggestDebounced(
        state.name,
        userState.token,
        userState.client,
        setOrganizations
      );
    } else setShowSuggestions(false);
  }, [state.name]);

  const generateLink = (state) => {
    state = Object.entries(state).filter(([key, value]) => !!value);
    const searchParams = new URLSearchParams(state);
    return `https://getfunded.inreachventures.com/form?${searchParams.toString()}`;
  };

  const preFill = (e, organization) => {
    setShowSuggestions(false);
    dispatch({ key: "name", value: organization.name });
    dispatch({ key: "organizationId", value: organization.id });
    dispatch({ key: "website", value: organization?.website_info?.url });
    dispatch({ key: "linkedin", value: organization?.linkedin });
  };

  return (
    <div class="container">
      <div class="row">
        <div class="col">
          <h1>Organization</h1>
          <div class="mb-5">
            <label for="nameInput" class="form-label">
              Organization Name
            </label>
            <input
              value={state.name}
              onChange={(e) => {
                dispatch({ key: "name", value: e.target.value });
              }}
              type="text"
              class="form-control form-control-lg"
              id="nameInput"
              data-testid="name"
            />
            {showSuggestions && organizations.length > 0 && (
              <div id="autosuggest">
                <ul class="list-group">
                  {organizations.map((organization) => (
                    <li key={organization.id} class="list-group-item">
                      <button
                        class="btn btn-sm btn-link"
                        onClick={(e) => preFill(e, organization)}
                      >
                        {organization.name}
                      </button>
                    </li>
                  ))}
                </ul>
                <button
                  class="btn btn-link close"
                  onClick={(e) => setShowSuggestions(false)}
                >
                  <i class="bi bi-x"></i> close
                </button>
              </div>
            )}
          </div>
          <div class="mb-5">
            <label for="idInput" class="form-label">
              Organization ID
            </label>
            <input
              value={state.organizationId}
              onChange={(e) =>
                dispatch({ key: "organizationId", value: e.target.value })
              }
              type="text"
              class="form-control form-control-lg"
              id="idInput"
              data-testid="id"
            />
          </div>
          <div class="mb-5">
            <label for="websiteInput" class="form-label">
              Organization Website
            </label>
            <input
              value={state.website}
              onChange={(e) =>
                dispatch({ key: "website", value: e.target.value })
              }
              type="url"
              class="form-control form-control-lg"
              id="websiteInput"
              data-testid="website"
            />
          </div>
          <div class="mb-5">
            <label for="orgLinkedinInput" class="form-label">
              Organization LinkedIn Profile
            </label>
            <input
              value={state.linkedin}
              onChange={(e) =>
                dispatch({ key: "linkedin", value: e.target.value })
              }
              type="url"
              class="form-control form-control-lg"
              id="orgLinkedinInput"
              data-testid="orgLinkedin"
            />
          </div>
        </div>
        <div class="col">
          <h1>Person</h1>
          <div class="mb-5">
            <label for="yourNameInput" class="form-label">
              Name
            </label>
            <input
              value={state.yourName}
              onChange={(e) =>
                dispatch({ key: "yourName", value: e.target.value })
              }
              type="text"
              class="form-control form-control-lg"
              id="yourNameInput"
              data-testid="yourName"
            />
          </div>
          <div class="mb-5">
            <label for="yourEmailInput" class="form-label">
              Email
            </label>
            <input
              value={state.yourEmail}
              onChange={(e) =>
                dispatch({ key: "yourEmail", value: e.target.value })
              }
              type="email"
              class="form-control form-control-lg"
              id="yourEmailInput"
              data-testid="yourEmail"
            />
          </div>
          <div class="mb-5">
            <label for="yourLinkedinInput" class="form-label">
              LinkedIn Profile
            </label>
            <input
              value={state.yourLinkedin}
              onChange={(e) =>
                dispatch({ key: "yourLinkedin", value: e.target.value })
              }
              type="email"
              class="form-control form-control-lg"
              id="yourLinkedinInput"
              data-testid="yourLinkedin"
            />
          </div>
          <h1>Introduced By</h1>
          <div class="mb-5">
            <label for="introducedByInput" class="form-label">
              Introduced By
            </label>
            <input
              value={state.introducedBy}
              onChange={(e) =>
                dispatch({ key: "introducedBy", value: e.target.value })
              }
              type="text"
              class="form-control form-control-lg"
              id="introducedByInput"
              data-testid="introducedBy"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <h1>
          Link
          <button
            disabled={copied}
            class="btn btn-outline-primary m-3"
            onClick={async (e) => {
              await navigator.clipboard.writeText(generateLink(state));
              await setCopied(true);
              setTimeout(async () => {
                await setCopied(false);
              }, 3000);
            }}
          >
            <i
              class={clsx(
                "bi",
                !copied && "bi-clipboard",
                copied && "bi-check"
              )}
            ></i>
          </button>
        </h1>
        <div id="result" data-testid="result">
          {generateLink(state)}
        </div>
      </div>
    </div>
  );
}

export default observer(App);
