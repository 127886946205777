import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    return (
      <div class="error-boundary">
        InReachVentures-funding-link-tool crashed. Please contact support.
      </div>
    );
  },
  domElementGetter(props) {
    return document.getElementById("content");
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
